import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    font-family: 'Sen', sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  p {
    color: #506690;
  }
`;