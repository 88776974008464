import React from 'react';
import {SectionWrapper} from "../hero/style";
import {AboutBenefit, AboutHeader, AboutHeaderTitle, Benefit, CommonSection, IconCircleWrapper} from "./style";
import feature01 from '../../assets/icon/feature01.png';
import feature02 from '../../assets/icon/feature02.png';
import feature04 from '../../assets/icon/feature04.png';
import circle from '../../assets/image/circle-shape.png';
import tiles from '../../assets/image/tiles.png';

const About = (props) => (
    <SectionWrapper bg={tiles} padding="6rem 0">
        <CommonSection>
            <IconCircleWrapper>
                <img src={circle} alt=""/>
            </IconCircleWrapper>
            <AboutHeader>
                <AboutHeaderTitle padding="0% 24% 0% 24%" style={{marginBottom: "12px"}} data-aos="fade-down">
                    <h1>PT Media Prima Jaringan, Penyedia Jasa Teknologi Informasi</h1>
                </AboutHeaderTitle>
                <AboutHeaderTitle padding="0% 26% 0% 26%" data-aos="fade-up">
                    <p>Tenaga ahli yang berpengalaman di bidangnya selama lebih dari 10 tahun telah bergabung dengan
                        kami
                        dan
                        kami yakin dapat mengembangkan teknologi informasi dengan tujuan mempermudah aktivitas di
                        berbagai
                        sektor.</p>
                </AboutHeaderTitle>
            </AboutHeader>
            <AboutBenefit>
                <Benefit data-aos="fade-down">
                    <img src={feature01} alt=""/>
                    <h1>Wifi Seamless</h1>
                    <p>Browsing berita, video sekarang udah semulus jalan tol</p>
                </Benefit>
                <Benefit data-aos="fade-up">
                    <img src={feature02} alt=""/>
                    <h1>Reliable Connection</h1>
                    <p>Nonton dan main game tanpa <em>lag</em> dengan jaringan internet yang stabil!</p>
                </Benefit>
                <Benefit data-aos="fade-down">
                    <img src={feature04} alt=""/>
                    <h1>High Speed Internet</h1>
                    <p>Maraton drama kesukaanmu udah ga akan buffering lagi sampai dengan 80Mbps</p>
                </Benefit>
            </AboutBenefit>
        </CommonSection>
    </SectionWrapper>
);

export default About;