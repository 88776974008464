import styled from "styled-components";

export const TestimonyFlex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
  }
`;

export const TestimonyLeft = styled.div`
  position: relative;
  display: none;

  @media screen and (min-width: 1024px) {
    width: 44%;
    display: flex;
  }
`;

export const TestimonyRight = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    width: 56%;

    & > h1 {
      font-size: 32px;
    }
  }
`;

export const BoxWrapper = styled.div`
  position: absolute;
  left: 30px;
  top: 80px;
  max-width: 347px;
  z-index: -1;
`;

export const Box = styled.div`
  background-color: #043d59;
  border-radius: 50px 100px 50px 100px;
  height: 400px;
  width: 315px;
`;

export const TestimonyRightFlex = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const QuoteWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #043d59;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const TestimonyDetail = styled.div`
  & > h1 {
    margin: 1rem 0 6px;
  }

  & > p {
    font-size: 18px;
    line-height: 1.5;
    font-style: italic;
  }

  @media screen and (min-width: 1024px) {

    & > p {
      font-size: 26px;
    }
  }
`;