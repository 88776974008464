import React from 'react';
import {HeroButton, SectionWrapper} from "../hero/style";
import {CommonSection} from "../about/style";
import {CheckContainer, CheckLeft, CheckRight, CheckServiceWrapper, SearchWrapper} from "./style";
import bgMap from '../../assets/image/bgmappurple.png';

const Check = (props) => (
    <SectionWrapper padding="4rem 0" backgroundColor="#dfe7ea">
        <CommonSection>
            <CheckServiceWrapper bg={bgMap}>
                <CheckContainer>
                    <CheckLeft>
                        <h1>Cek Layanan</h1>
                        <p>Masukan emailmu dan kami akan memberitahu info mengenai layanan di tempatmu.</p>
                    </CheckLeft>
                    <CheckRight>
                        <SearchWrapper>
                            <input type="text"/>
                            <HeroButton>
                                <span>Tanya Kami</span>
                            </HeroButton>
                        </SearchWrapper>
                    </CheckRight>
                </CheckContainer>
            </CheckServiceWrapper>
        </CommonSection>
    </SectionWrapper>
);

export default Check;