import styled from "styled-components";

export const PackageGrid = styled.div`
  display: grid;
  margin-top: 2rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const PackageCard = styled.div`
  background: #fff;
  box-shadow: 0px 20px 50px 0px rgba(11.00000000000002, 6.000000000000001, 20, 0.05);
  padding: 10% 12% 12%;
  margin: 5% 5% 5% 5%;
  border-radius: 8px;
`;

export const CardBandwidth = styled.div`
  padding: 6% 8% 6% 8%;
  border-radius: 8px;
  background: #dfe7ea;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const CardBandwidthDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardPrice = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin-top: 1rem;

  & > h1 {
    margin: 0;
    font-size: 42px;
  }

  & > span {
    margin-bottom: 8px;
  }
`;

export const PackageBenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 1rem;
`;

export const PackageBenefit = styled.div`
  display: flex;
  gap: 1.25rem;
`;

export const BenefitCheckWrapper = styled.div`
  border-radius: 50%;
  background: #1b9484;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BenefitButton = styled.button`
  background: #1b9484;
  border-radius: 8px;
  padding: 15px 35px 15px 35px;
  border: 0;
  margin-top: 1.25rem;
  cursor: pointer;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform .4s;

  &:hover {
    border-radius: 18px;
  }

  & > h1 {
    font-size: 18px;
    color: #fff;
  }
`;