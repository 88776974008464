import React from 'react';
import {SectionWrapper} from "../hero/style";
import {CommonSection} from "../about/style";
import {CompanyGrid} from "./style";
import img01 from '../../assets/icon/puripark.png';
import img02 from '../../assets/icon/oxygen.png';
import img03 from '../../assets/logo/logo.png';

const Company = (props) => (
    <SectionWrapper padding="4rem 0" backgroundColor="#dfe7ea">
        <CommonSection>
            <CompanyGrid>
                <img src={img03} alt="" data-aos="flip-up"/>
                <img src={img01} alt="" data-aos="flip-up"/>
                <img src={img02} alt="" data-aos="flip-up"/>
            </CompanyGrid>
        </CommonSection>
    </SectionWrapper>
);

export default Company;