import styled from "styled-components";

export const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #043d59;
  z-index: 9999;

  @media screen and (min-width: 1024px) {
    padding: 1rem;
  }
`;

export const NavbarContainer = styled.nav`
  max-width: 1140px;
  margin: 0 auto;
  background: white;
  padding: 0 8px 0 0;

  @media screen and (min-width: 1024px) {
    display: block;
    padding: 0 2rem;
    border-radius: 12px;
  }
`;

export const NavbarMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    width: 100px;
  }
`;

export const NavbarMenu = styled.div`
  display: none;
  gap: 2rem;

  & > a {
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    color: #333;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const NavbarButton = styled.div`
  padding: 15px 30px 15px 30px;
  border-radius: 6px;
  background: #1b9484;

  & > span {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }
`;