import styled from "styled-components";

export const CompanyGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;

  & > img {
    width: 200px;
    height: 100%;
    filter: brightness(100%) contrast(0%) saturate(100%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.4s;

    &:hover {
      filter: none;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;