import React from 'react';
import {
    HeroButton,
    HeroImageSection,
    HeroImgWrapper,
    HeroLeft,
    HeroQuotes,
    HeroQuotesWrapper,
    HeroRight,
    SectionContainer,
    SectionWrapper,
    StarWrapper
} from "./style";
import img01 from '../../assets/image/img007.jpg';
import img02 from '../../assets/image/002.png';
import bghero from '../../assets/image/img05.jpg';
import * as Ai from 'react-icons/ai';

const Hero = (props) => (
    <SectionWrapper backgroundColor="#043d59" bg={bghero} filter="grayscale(.5)">
        <SectionContainer>
            <HeroLeft data-aos="fade-right">
                <h1>PT Media Prima Jaringan</h1>
                <p>Kami adalah penyedia jasa teknologi informasi terkemuka di Indonesia yang memiliki komitmen dan
                    integritas tinggi terutama dalam bidang internet.</p>
                <HeroButton style={{marginTop: "3rem"}}
                            onClick={() => window.open('https://wa.me/6285881771779?text=Hi MPJ, Saya mau tanya-tanya soal Produk MPJ')}>
                    <span>Tanya Kami</span>
                </HeroButton>
            </HeroLeft>
            <HeroRight data-aos="fade-left">
                <HeroImgWrapper margin="0 0em -12em 0">
                    <img src={img02} alt=""/>
                </HeroImgWrapper>
                <HeroImgWrapper margin="-12em 0em 0em 5em">
                    <img src={img01} alt=""
                         style={{
                             backgroundImage: "linear-gradient(160deg, #6C41FF 0%, #5680FF 100%)",
                             borderRadius: "80px 40px 80px 40px"
                         }}/>
                </HeroImgWrapper>
                <HeroImageSection>
                    <HeroQuotesWrapper>
                        <HeroQuotes>
                            <StarWrapper>
                                <Ai.AiFillStar color="#ffa804" size={20}/>
                                <Ai.AiFillStar color="#ffa804" size={20}/>
                                <Ai.AiFillStar color="#ffa804" size={20}/>
                                <Ai.AiFillStar color="#ffa804" size={20}/>
                                <Ai.AiFillStar color="#ffa804" size={20}/>
                            </StarWrapper>
                            <p>"Penyedia Jasa Teknologi Informasi Terkemuka di Indonesia."</p>
                            <h1>Media Prima Jaringan</h1>
                        </HeroQuotes>
                    </HeroQuotesWrapper>
                </HeroImageSection>
            </HeroRight>
        </SectionContainer>
    </SectionWrapper>
);

export default Hero;