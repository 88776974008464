import styled from "styled-components";

export const FooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;

  @media screen and (min-width: 1024px) {
    column-gap: 2rem;
    flex-direction: row;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  max-width: 300px;
  margin-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  & > img {
    width: 175px;
  }

  & > p {
    line-height: 1.5;
    color: #C2B8E5;
    font-size: 15px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    & > p {
      font-size: 1rem;
    }
  }
`;

export const FooterHeader = styled.div`
  & > h1 {
    font-size: 18px;
    color: #eee;
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 28px;
    }
  }
`;

export const FooterMenu = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1.5rem;

  & > a {
    color: #C2B8E5;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  & > p {
    color: #C2B8E5;
    line-height: 1.4;
    font-size: 14px;
  }

  & > form > input {
    display: block;
    padding: 6px 8px;
    border-radius: 6px;
    border: none;
    outline: none;
    margin-bottom: 8px;
    width: 100%;
  }

  & > form > textarea {
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 6px 8px;
  }

  & > form > button {
    padding: 4px 6px;
    border-radius: 6px;
    border: 0;
    outline: none;
    margin-top: 6px;
    width: 75px;
  }

  @media screen and (min-width: 1024px) {
    & > a, & > p {
      font-size: 16px;
    }
  }
`;

export const FooterBottom = styled.div`
  border-top: 1px solid #FFFFFF0F;
  padding: 1em 0em 0em 0em;
  margin-top: 2rem;
`;

export const FooterBottomFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  & > h1 {
    color: #C2B8E5;
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;

    & > h1 {
      margin-bottom: 0;
      font-size: 18px;
    }
  }
`;

export const FooterBottomDetail = styled.div`
  display: flex;
  gap: 2rem;

  & > a {
    text-decoration: none;
    color: #C2B8E5;
    font-size: 14px;
  }


`;