import './App.css';
import "aos/dist/aos.css";
import AOS from "aos";
import Navbar from "./section/navbar";
import Hero from "./section/hero";
import About from "./section/about";
import Product from "./section/product";
import Check from "./section/check";
import Company from "./section/company";
import Testimony from "./section/testimony";
import Bridging from "./section/bridging";
import Footer from "./section/footer";
import {Component} from "react";

class App extends Component {
    componentDidMount() {
        AOS.init({
            duration: 1000,
        });
    }

    render() {
        return (
            <>
                <Navbar/>
                <Hero/>
                <About/>
                <Product/>
                <Check/>
                <Company/>
                <Testimony/>
                <Bridging/>
                <Footer/>
            </>
        );
    }

}

export default App;
