import styled from "styled-components";

export const ProductSectionContainer = styled.div`
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 3rem;
  flex-direction: column-reverse;
  padding: 2rem;
  position: relative;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
  }
`;

export const RightShapeWrapper = styled.div`
  position: absolute;
  bottom: -2%;
  right: 0;
`;

export const LeftShapeWrapper = styled.div`
  position: absolute;
  top: -10%;
  left: 0;
  z-index: -1;

  & > img {
    width: 100%;
  }
`;

export const ProductLeft = styled.div`
  @media screen and (min-width: 1024px) {
    width: 54%;
  }
`;

export const ProductImgLeftWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin: 2em 5em 0em 0em;
    display: block;
  }
`;

export const ProductRightWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  & > p {
    font-size: 1rem;
    line-height: 1.5;
  }

  @media screen and (min-width: 1024px) {
    width: 46%;
  }
`;

export const ImgWrapper = styled.div`
  z-index: 4;

  & > img {
    max-width: 100%;
    height: 300px;
    object-fit: cover;
  }

  @media screen and (min-width: 1024px) {
    & > img {
      object-fit: cover;
      height: 440px;
      border-radius: 60px 100px 60px 100px;
    }
  }
`;

export const BoxWrapper = styled.div`
  position: absolute;
  left: 200px;
  top: -36px;
  max-width: 310px;
  z-index: -1;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const Box = styled.div`
  background-color: #043d59;
  border-radius: 30px 100px 30px 100px;
  height: 380px;
  width: 315px;
`;

export const BandwidthWrapper = styled.div`
  margin-top: -7em;
  width: 100%;
  z-index: 999;
  position: relative;
  padding: 0em 2em 0em 2em;

  @media screen and (min-width: 1024px) {
    padding: 0 11em 0 4em;
  }
`;

export const Bandwidth = styled.div`
  background: #fff;
  width: 100%;
  padding: 10% 10% 10% 10%;
  box-shadow: 0 10px 45px 0px rgba(11.00000000000002, 6.000000000000001, 20, 0.08);
  border-radius: 20px;
  text-align: center;

  & > img {
    width: 100%;
    margin-bottom: -3em;
  }

  & > h1 {
    font-size: 48px;
  }

  & > h2 {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 1024px) {
    & > img {
      margin-bottom: -5em;
    }
  }
`;

export const ProductGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const CheckWrapper = styled.div`
  background-color: #b9bece;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;