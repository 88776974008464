import React from 'react';
import {SectionWrapper} from "../hero/style";
import {CommonSection} from "../about/style";
import {
    Box,
    BoxWrapper,
    QuoteWrapper,
    TestimonyDetail,
    TestimonyFlex,
    TestimonyLeft,
    TestimonyRight,
    TestimonyRightFlex
} from "./style";
import img01 from '../../assets/image/img03.png';
import * as Fa from 'react-icons/fa';
import bg from '../../assets/image/bg001.png'

const Testimony = () => (
    <SectionWrapper bg={bg} padding="5rem 0 8rem" id="testimonies">
        <CommonSection>
            <TestimonyFlex>
                <TestimonyLeft data-aos="fade-down">
                    <img src={img01} alt=""/>
                    <BoxWrapper>
                        <Box/>
                    </BoxWrapper>
                </TestimonyLeft>
                <TestimonyRight data-aos="fade-up">
                    <h1>Apa kata Pelanggan Kami</h1>
                    <TestimonyRightFlex>
                        <QuoteWrapper>
                            <Fa.FaQuoteLeft color="#eee"/>
                        </QuoteWrapper>
                        <TestimonyDetail>
                            <p>"If you aren’t sure, always go for internet service provider. Thank you for making it
                                painless, pleasant & most of all hassle free!"</p>
                            <h1>Ariel Noah</h1>
                            <span>Owner of Hall 15</span>
                        </TestimonyDetail>
                    </TestimonyRightFlex>
                </TestimonyRight>
            </TestimonyFlex>
        </CommonSection>
    </SectionWrapper>
);

export default Testimony;