import React from 'react';
import {SectionWrapper} from "../hero/style";
import img01 from '../../assets/image/img006.jpg';
import img02 from '../../assets/image/img02.png';
import {
    Bandwidth,
    BandwidthWrapper,
    Box,
    BoxWrapper,
    CheckWrapper,
    ImgWrapper,
    LeftShapeWrapper,
    ProductCard,
    ProductGrid,
    ProductImgLeftWrapper,
    ProductLeft,
    ProductRightWrapper,
    ProductSectionContainer,
    RightShapeWrapper
} from "./style";
import * as Bs from 'react-icons/bs';
import rightShape from '../../assets/image/right-shape.png';
import leftShape from '../../assets/image/left-shape.png';
import tiles from '../../assets/image/tiles.png';

const Product = () => (
    <SectionWrapper bg={tiles} padding="5rem 0 12rem" id="products">
        <RightShapeWrapper>
            <img src={rightShape} alt=""/>
        </RightShapeWrapper>
        <LeftShapeWrapper>
            <img src={leftShape} alt=""/>
        </LeftShapeWrapper>
        <ProductSectionContainer>
            <ProductLeft data-aos="fade-right">
                <ProductImgLeftWrapper>
                    <ImgWrapper>
                        <img src={img01} alt=""/>
                    </ImgWrapper>
                    <BoxWrapper>
                        <Box/>
                    </BoxWrapper>
                    <BandwidthWrapper>
                        <Bandwidth>
                            <img src={img02} alt=""/>
                            <span>Up To</span>
                            <h1>80</h1>
                            <h2>Mbps</h2>
                            <p>Aman dan Cepat</p>
                        </Bandwidth>
                    </BandwidthWrapper>
                </ProductImgLeftWrapper>
            </ProductLeft>
            <ProductRightWrapper data-aos="fade-up">
                <h1> Layanan Kami</h1>
                <p>Dengan majunya dunia teknologi informasi di zaman ini, kami bertekad untuk memenuhi kebutuhan
                    teknologi informasi untuk masyarakat di berbagai sektor antara lain dunia kerja, pendidikan, hotel,
                    rumah sakit, perkantoran dan
                    hiburan.</p>
                <ProductGrid>
                    <ProductCard>
                        <CheckWrapper>
                            <Bs.BsCheck size={20} color="#073d5c"/>
                        </CheckWrapper>
                        <span>Penyediaan Jaringan (Backbone)</span>
                    </ProductCard>
                    <ProductCard>
                        <CheckWrapper>
                            <Bs.BsCheck size={20} color="#073d5c"/>
                        </CheckWrapper>
                        <span>Internet dan TV</span>
                    </ProductCard>
                    <ProductCard>
                        <CheckWrapper>
                            <Bs.BsCheck size={20} color="#073d5c"/>
                        </CheckWrapper>
                        <span>Digital Signage</span>
                    </ProductCard>
                    <ProductCard>
                        <CheckWrapper>
                            <Bs.BsCheck size={20} color="#073d5c"/>
                        </CheckWrapper>
                        <span>Pembuatan Aplikasi</span>
                    </ProductCard>
                    <ProductCard>
                        <CheckWrapper>
                            <Bs.BsCheck size={20} color="#073d5c"/>
                        </CheckWrapper>
                        <span>Manage Service</span>
                    </ProductCard>
                </ProductGrid>
            </ProductRightWrapper>
        </ProductSectionContainer>
    </SectionWrapper>
);

export default Product;