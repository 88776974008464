import styled from "styled-components";

export const CheckServiceWrapper = styled.div`
  border-radius: 12px;
  background-color: #073d5c;
  width: 100%;
  margin-top: -8em;
  padding: 4em 2em 4em 2em;
  background-image: url(${({bg}) => bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 1024px) {
    margin-top: -12em;
  }
`;

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const CheckLeft = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-size: 44px;
    color: #fff;
    margin-bottom: 20px;
  }

  & > p {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;

export const CheckRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;

export const SearchWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  display: flex;

  & > input {
    flex: 1;
    border: 0;
    padding-left: 10px;
    font-size: 14px;
  }
`;