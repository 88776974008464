import React from 'react';
import {SectionWrapper} from "../hero/style";
import {AboutHeader, AboutHeaderTitle, CommonSection} from "../about/style";
import {CheckServiceWrapper} from "../check/style";
import bgDots from '../../assets/image/bgdots.png';
import {BenefitButton} from "../package/style";

const Bridging = (props) => (
    <SectionWrapper padding="4rem 0">
        <CommonSection>
            <CheckServiceWrapper bg={bgDots}>
                <AboutHeader>
                    <AboutHeaderTitle padding="0% 10% 0% 10%" style={{marginBottom: "12px"}}>
                        <h1 style={{color: "#fff"}}>Kami siap menjalin kerjasama dengan Anda</h1>
                    </AboutHeaderTitle>
                    <AboutHeaderTitle padding="0% 26% 0% 26%">
                        <p style={{color: "#eee"}}>Jika ingin berinvestasi maupun menggunakan pelayanan jasa kami untuk
                            berlangganan, silahkan
                            segera menghubungi kami.</p>
                    </AboutHeaderTitle>
                    <BenefitButton
                        onClick={() => window.open('https://wa.me/6285881771779?text=Hi MPJ, Saya mau tanya menjalin kerjasama dengan Anda')}>
                        <h1>Hubungi Kami</h1>
                    </BenefitButton>
                </AboutHeader>
            </CheckServiceWrapper>
        </CommonSection>
    </SectionWrapper>
);

export default Bridging;