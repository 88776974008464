import React from 'react';
import {SectionWrapper} from "../hero/style";
import {CommonSection} from "../about/style";
import {
    FooterBottom,
    FooterBottomDetail,
    FooterBottomFlex,
    FooterContainer,
    FooterGrid,
    FooterHeader,
    FooterLeft,
    FooterMenu,
    FooterRight
} from "./style";
import logo from '../../assets/logo/logo.png';

const Footer = (props) => (
    <SectionWrapper padding="5rem 0 2rem" backgroundColor="#1C0F33">
        <CommonSection>
            <FooterGrid>
                <FooterLeft>
                    <FooterContainer>
                        <img src={logo} alt=""/>
                        <p>Apartemen Puri Parkview Tower AAA / L1 / 16
                            Jl. Pesanggrahan Raya No. 88 Meruya Utara, Kembangan Jakarta Barat - Indonesia</p>
                        <p>+62-21 5890 2704</p>
                    </FooterContainer>
                </FooterLeft>
                <FooterRight>
                    <FooterContainer>
                        <FooterHeader>
                            <h1>Service</h1>
                        </FooterHeader>
                        <FooterMenu>
                            <a href="#products">Products</a>
                            <a href="#packages">Packages</a>
                            <a href="#testimony">Testimony</a>
                        </FooterMenu>
                    </FooterContainer>
                </FooterRight>
                <FooterContainer>
                    <FooterHeader>
                        <h1>Hubungi Kami</h1>
                    </FooterHeader>
                    <FooterMenu>
                        <form action="">
                            <input type="text" placeholder="Nama"/>
                            <input type="text" placeholder="No Handphone"/>
                            <input type="text" placeholder="Email"/>
                            <textarea name="" id="" cols="30" rows="5" placeholder="Pesan"></textarea>
                            <button type="submit">Kirim</button>
                        </form>
                    </FooterMenu>
                </FooterContainer>
            </FooterGrid>
            <FooterBottom>
                <FooterBottomFlex>
                    <h1>&#169; PT Media Prima Jaringan 2023</h1>
                    <FooterBottomDetail>
                        <a href="#a">Terms of Use</a>
                        <a href="#a">Privacy Policy</a>
                    </FooterBottomDetail>
                </FooterBottomFlex>
            </FooterBottom>
        </CommonSection>
    </SectionWrapper>
);

export default Footer;