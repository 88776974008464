import styled from "styled-components";

export const SectionWrapper = styled.section`
  background-color: ${({backgroundColor}) => backgroundColor};
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: ${({padding}) => padding ? padding : "10rem 0"};
  background-image: url(${({bg}) => bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: ${({filter}) => filter};
  position: relative;

  @media screen and (min-width: 1024px) {
    overflow: visible;
    filter: none;
  }
`;

export const SectionContainer = styled.div`
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 3rem;
  flex-direction: column;
  padding: 2rem;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
  }
`;

export const HeroLeft = styled.div`
  text-align: center;

  & > h1 {
    font-size: 38px;
    color: #fff;
    margin-bottom: 20px;
  }

  & > p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
  }

  @media screen and (min-width: 1024px) {
    width: 55.332%;
    text-align: left;

    & > h1 {
      font-size: 62px;
    }

    & > p {
      font-size: 17px;
    }
  }
`;

export const HeroRight = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    width: 44.6%;
  }
`;

export const HeroImgWrapper = styled.div`
  margin: ${({margin}) => margin};

  @media screen and (min-width: 1024px) {
    & > img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }
`;

export const HeroButton = styled.button`
  padding: 15px 30px 15px 30px;
  border-radius: 6px;
  background: #1b9484;
  outline: none;
  border: 0;

  & > span {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }
`;

export const HeroImageSection = styled.div`
  margin-top: -3rem;
  padding: 0 2em 0 8em;
`;

export const HeroQuotesWrapper = styled.div`
  z-index: 999;
  width: 100%;
`;

export const HeroQuotes = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 10px 50px 0px rgba(11.00000000000002, 6.000000000000001, 20, 0.2);
  padding: 7%;
  border-radius: 16px;
  width: 100%;

  & > p {
    margin: 8px 0 20px;
    line-height: 1.5;
    font-size: 16px;
  }

  & > h1 {
    font-size: 18px;
  }
`;

export const StarWrapper = styled.div`
  display: flex;
  gap: 8px;
`;