import styled, {keyframes} from "styled-components";

export const CommonSection = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
`;

export const AboutHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AboutHeaderTitle = styled.div`
  padding: 1rem;
  text-align: center;

  & > h1 {
    font-size: 38px;

    & > span {
      color: red;
    }
  }

  & > p {
    font-size: 17.5px;
    margin-top: 12px;
    line-height: 1.5;
  }

  & > span {
    margin-top: 5px;
    font-size: 11px;
    display: inline-block;
  }

  @media screen and (min-width: 1024px) {
    padding: ${({padding}) => padding};
    & > h1 {
      font-size: 40px;
    }
  }
`;

export const AboutBenefit = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 4rem;
  padding: 1rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
  }
`;

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 75px;
  }

  & > h1 {
    font-size: 21px;
    margin: 1rem 0 8px;
  }

  & > p {
    line-height: 1.5;
    text-align: center;
  }
`;

const spinningAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconCircleWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 1%;
  animation: ${spinningAnimation} 6s infinite linear;
`;
