import React from 'react';
import {NavbarButton, NavbarContainer, NavbarMenu, NavbarMenuContainer, NavbarWrapper} from "./style";
import logo from '../../assets/logo/logo.png';

const Navbar = (props) => (
    <NavbarWrapper>
        <NavbarContainer>
            <NavbarMenuContainer>
                <img src={logo} alt=""/>
                <NavbarMenu>
                    <a href="/">Home</a>
                    <a href="#products">Products</a>
                    <a href="#packages">Packages</a>
                    <a href="#testimonies">Testimonies</a>
                </NavbarMenu>
                <NavbarButton
                    onClick={() => window.open('https://wa.me/6285881771779?text=Hi MPJ, Saya mau tanya soal produk MPJ')}>
                    <span>Tanya Kami</span>
                </NavbarButton>
            </NavbarMenuContainer>
        </NavbarContainer>
    </NavbarWrapper>
);

export default Navbar;